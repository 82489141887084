import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "../button/button";

import "./grid.scss";

const Grid = (props) => {
	const { data } = props;
	
	const blockClasses = ['has-background-color-cream'];

	const classes = `block block-grid ${[...blockClasses]}`;

	return(
		<section className={classes} id="palvelut">
			<div className="wrap">
				{ data.title && (
				<h2>{ data.title }</h2>
				)}
				<p className="has-font-style-body-medium description">{ data.text }</p>
				
				<div class="grid-generic">
				{
					data.gridItems && data.gridItems.map((item) => {
						return(
							<div class="grid-item">
								<figure class="grid-item__image">
									{typeof item.image === "object" &&
										<GatsbyImage className="grid-item__image" image={item.image.childImageSharp.gatsbyImageData} alt=""/>
									}

									{typeof item.button === "object" &&
										<Button className="grid-item__button" url={item.button.url} text={item.button.text} color="blue" arrow={true} />
									}
								</figure>
								
								<div className="grid-item__text">
									<h3 className="grid-item__title">{item.title}</h3>
									<p className="grid-item__description">{item.description}</p>
								</div>
							</div>
						)
					})
				}
				</div>
			</div>
		</section>
	)
}

export default Grid