import React from "react"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Button from "../button/button";
import DownloadIcon from "../../../images/cloud--download.svg"

import "./contentblock.scss";

const humanFileSize = (size) => {
	var i = Math.floor(Math.log(size) / Math.log(1024));
	return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const ContentBlock = (props) => {
	const { data } = props
	const { title, slug, intro, buttons, color, mediaDownloads } = data

	const blockClasses = [];

	if (!color) {
		blockClasses.push('has-background-color-cream');
	} else {
		blockClasses.push('has-background-color-' + color);
	}

	const classes = `block block-content ${[...blockClasses]}`;

	return (
		<section className={classes} id={slug}>
			<div className="wrap">
				{title && (
					<h2 class="block-content__title" dangerouslySetInnerHTML={{ __html: title }}></h2>
				)}

				{intro && (
					<div class="block-content__intro">{renderRichText(intro)}</div>
				)}

				{buttons && (
					<div class="block-content__buttons">
						{buttons.map((button) => {
							return (
								<Button url={button.url} text={button.label} color={button.color} />
							)
						})}
					</div>
				)}

				{mediaDownloads && (
					<div class="block-content__media-downloads">
						{mediaDownloads.map((download) => {
							return (
								<div class="media-download">
									<div class="media-download__title">{download.title}</div>
									<div class="media-download__filetype">{download.filename.substr(download.filename.lastIndexOf('.') + 1)}</div>
									<div class="media-download__size">{humanFileSize(download.size)}</div>
									<a class="media-download__download" href={download.localFile.publicURL} download>Lataa <img class="media-download__download__icon" src={DownloadIcon} alt="" width="27" height="27" /></a>
								</div>
							)
						})}
					</div>
				)}
			</div>
		</section>
	)
}

export default ContentBlock