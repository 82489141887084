import React from "react"

import "./contact.scss";

const Contact = (props) => {
	const { data } = props;
	const { title, contactItems } = data
	
	const blockClasses = ['has-background-color-white'];

	const classes = `block block-contact ${[...blockClasses]}`;

	return(
		<section className={classes} id="yhteystiedot">
			<div className="wrap">
				{ title && (
				<h2>{ title }</h2>
				)}
				
				<div class="contact-container">
				{
					contactItems && contactItems.map((item) => {
						return(
							<div class="contact">
								<h3 class="contact__label">{item.label}</h3>
								<div class="contact__name">{item.name}</div>
								<div class="contact__line">{item.person}</div>
								<div class="contact__line">{item.address}</div>
								{item.phone && (
									<div class="contact__line">
										<a href={"tel:" + item.phone.replace(/\D/g, "")}>{item.phone}</a>
									</div>
								)}
								{item.email && (
									<div class="contact__line">
										<a href={"mailto:" + item.email}>{item.email}</a>
									</div>
								)}
								<div class="contact__line">{item.hours}</div>
							</div>
						)
					})
				}
				</div>
			</div>
		</section>
	)
}

export default Contact