const ContactItems = [
	{
		label: "Huoltoyhtiö",
		name: "Kiinteistöhuolto Määttä Oy",
		address: "Snellmaninkatu 19-21 D, 00170 Helsinki",
		phone: "040 5922997",
		email: "katajanokka@kiinteistohuoltomaatta.fi"
	},
	{
		label: "Asuntojen vuokraus",
		name: "Kodisto Asuntovuokraus",
		url: "https://asukas.kodisto.fi",
		phone: "010 389 6049",
		email: "asunnot@newsec.fi",
		hours: "arkisin 8:30-16:00"
	},
	{
		label: "Vuokravalvonta",
		name: "Newsec Kodisto ",
		url: "https://asukas.kodisto.fi",
		phone: "010 389 6003",
		email: "vuokrahallinto@newsec.fi",
		hours: "arkisin 8:30-16:00"
	},
	{
		label: "Isännöinti",
		name: "Kodisto Isännöinti",
		url: "https://asukas.kodisto.fi",
		phone: "010 389 6002",
		hours: "arkisin 8:30-16:00",
		email: "isannointi@newsec.fi"
	},
	{
		label: "Avaimet",
		name: "BLC Turva Oy Yrjönkatu",
		person: "Yrjönkatu 23B 2.krs (Forum)",
		address: "00100 Helsinki",
		phone: "0600 04450",
		email: "avainhallinta.yrjonkatu@blc.fi"
	}
]

export default ContactItems