import * as React from "react"
import { graphql } from "gatsby"

import HeaderBlock from "../components/header/headerBlock"
import FooterBlock from "../components/footer/footerBlock"

import Seo from "../components/seo"
import HeroPlain from "../components/blocks/hero/heroplain"
import Grid from "../components/blocks/grid/grid"
import Contact from "../components/blocks/contact/contact"
import ContactItems from "../data/contactItems"
import MultiContent from "../components/blocks/multicontent/multicontent"
import ContentBlock from "../components/blocks/contentblock/contentblock"

export const query = graphql`
  query {
    contentfulPage(contentful_id: {eq: "1fXL43NymdHocNZvgUgsm3"}) {
      menuItems {
        label
        url
      }
      title
      footer {
        address
        sections {
          order
          title
          content {
            title
            content {
              raw
            }
          }
        }
      }
    }
    imageHero: file(relativePath: { eq: "asukkaille/heroplain/jungmann-asukkaille-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 46)
      }
    }
    imageHeroPortrait: file(
      relativePath: { eq: "asukkaille/heroplain/jungmann-asukkaille-image.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: BLURRED, quality: 87)
      }
    }
    imageHeroPortrait400: file(
      relativePath: { eq: "asukkaille/heroplain/jungmann-asukkaille-image.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED, quality: 87)
      }
    }

    multiContent: contentfulContentSection(contentful_id: {eq: "2fFEkD97z8HKDF1DwnxJMo"}) {
      title
      multiSection {
        title
        slug
        order
        intro {
          raw
        }
        image {
          gatsbyImageData(width: 600)
        }
        swapImageAndTitleLocation
        content {
          title
          content {
            raw
          }
        },
        buttons {
          url
          label
          color
          arrow
        }
      }
    }
    contentBlock: contentfulContentBlock(contentful_id: {eq: "10cEKBCXWBiUzP8to51F2b"}) {
      title
      slug
      color
      intro {
        raw
      }
      buttons {
        url
        label
        color
        arrow
      }
    }
    contentBlockMediaDownloads: contentfulContentBlock(contentful_id: {eq: "2mdUwkhzrhUACCLbWSrXFk"}) {
      title
      slug
      color
      intro {
        raw
      }
      buttons {
        url
        label
        color
        arrow
      }
      mediaDownloads {
        title
        url
        size
        filename
        localFile {
          publicURL
        }
      }
    }
  }
`

const AsukkaillePage = ({ data }) => {
  const heroPlainData = {
    image: data.imageHero,
    imagePortrait: data.imageHeroPortrait,
    imagePortrait400: data.imageHeroPortrait400,
    text: "HYVÄ UUSI<br>JUNGMANNIN ASUKAS",
    links: [
      {
        label: "Muuttoilmoitus",
        url: "https://kiinteistohuoltomaatta.fi/lomakkeet/muuttoilmoitus/"
      },
      {
        label: "Vikailmoitus",
        url: "https://m.fimx.fi/extjulmoform/undefined/zwhayoac"
      },
      {
        label: "Vuokraus",
        url: "https://kodisto.fi/"
      },
      {
        label: "Koti Hotel",
        url: "https://kotihotel.fi/katajanokka/"
      },
      {
        label: "Omago yhteiskäyttöauto",
        url: "https://tili.omago.fi/frontpage"
      },
    ]
  }

  const textBlockData = {
    text: "Toivotamme Teidät tervetulleeksi Jungmanniin ja toivomme, että viihdyt uudessa kodissasi! Tämä Asumisopas sisältää tietoja kiinteistöön ja asuinhuoneiston käyttämiseen liittyvistä asioista. Oppaaseen on kerätty tarpeellisia ja hyödyllisiä asioita viihtyisän sekä turvallisen asumisen toteuttamiseksi.",
  }

  const contactBlockData = {
    title: "Yhteystiedot",
    contactItems: ContactItems
  }

  return (
    <>
      <HeaderBlock data={data.contentfulPage} />
      <main>
        <Seo title="Asukkaille" />
        <HeroPlain data={heroPlainData} />
        <Grid data={textBlockData} />
        <Contact data={contactBlockData} />
        <MultiContent data={data.multiContent} />
        <ContentBlock data={data.contentBlock} />
        <ContentBlock data={data.contentBlockMediaDownloads} />
      </main>
      <FooterBlock data={data.contentfulPage.footer} />
    </>
  )
}

export default AsukkaillePage