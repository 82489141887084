import React from "react"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import "./heroplain.scss";

import Button from "../button/button"

const HeroPlain = (props) => {
	const { data } = props;
	const { image, imagePortrait, imagePortrait400, text, links } = data;

	const blockClasses = [];

	const classes = `block block-heroplain ${[...blockClasses]}`;

	const images = withArtDirection(image.childImageSharp.gatsbyImageData, [
		{
			media: "(max-width: 600px) and (min-width: 401px)",
			image: imagePortrait.childImageSharp.gatsbyImageData,
		},
		{
			media: "(max-width: 400px)",
			image: imagePortrait400.childImageSharp.gatsbyImageData,
		},
	])

	return (
		<section className={classes}>
			<div className="wrap">
				<h1 dangerouslySetInnerHTML={{ __html: text }}></h1>
				<nav class="block-heroplain-menu">
					<h2>Pikalinkit</h2>
					<div class="block-heroplain-menu__buttons">
						{links.map((link) => {
							return (
								<Button url={link.url} text={link.label} color={'white'} />
							)
						})}
					</div>
				</nav>
			</div>
			<GatsbyImage class="block-heroplain__background-image" image={images} alt="" as="figure" loading="eager" objectFit="cover" />
		</section>
	)
}

export default HeroPlain