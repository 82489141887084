import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../../images/jungmann-logo.svg"
import Icon from "../../images/jungmann-icon.svg"

import "./header.scss"

const HeaderBlock = (props) => {
	const { data } = props
	const { menuItems } = data
	const [isActive, setActive] = useState(false);

	const toggleMenu = () => {
		if (isActive) {
			setActive(false);
		} else {
			setActive(true);
		}
	};

	return (
		<header>
			<div className="wrap">
				<Link to="/" class="logo-container"><img src={Logo} alt="Jungmann logo" width="175" height="22" /></Link>
				<nav className={isActive ? 'is-active' : 'is-not-active'}>
					<ul>
						{menuItems && menuItems.map((item) => {
							return (
								<li><Link to={item.url} onClick={toggleMenu}>{item.label}</Link></li>
							)
						})}
					</ul>
				</nav>
				<div class="nav__right">
					<button onClick={toggleMenu} aria-label="Toggle mobile menu" className={"mobile-menu-toggle " + (isActive ? 'is-active' : 'is-not-active')}><img src={Icon} alt="Jungmann logo" width="28" height="28" /></button>
				</div>
			</div>
		</header>
	)
}

export default HeaderBlock
